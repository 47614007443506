import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';
import { useLocation } from 'react-router-dom';
import SidebarLayout from 'src/layouts/SidebarLayout';
import SidebarLayoutMatrix from 'src/layouts/SidebarLayoutMatrix';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Default from 'src/content/dashboards/Default';

import {
  ISigma_Admin_Autorizada,
  Sigma_Account_Auth
} from 'src/models/sigma/Sigma_Account_Auth';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const HomeDefault = Loader(
  lazy(() => import('src/content/dashboards/Default'))
);

const LoginAdmin = Loader(
  lazy(() => import('src/content//administracion/Login'))
);

const LoginSigma = Loader(lazy(() => import('src/content/Login')));

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

const Alpha = Loader(lazy(() => import('src/content/dashboards/Alpha')));

const AlphaCore = Loader(lazy(() => import('src/content/applications/alpha')));

// ALPHA APPLICATIONS
const AlphaComprobantes = Loader(
  lazy(() => import('src/content/applications/alpha/Comprobantes'))
);
const AlphaPedidos = Loader(
  lazy(() => import('src/content/applications/alpha/Pedidos'))
);
const AlphaClientes = Loader(
  lazy(() => import('src/content/applications/alpha/Clientes'))
);
const AlphaProductos = Loader(
  lazy(() => import('src/content/applications/alpha/Productos'))
);
const AlphaCaja = Loader(
  lazy(() => import('src/content/applications/alpha/InspectorCaja'))
);
const AlphaRetiroIngreso = Loader(
  lazy(() => import('src/content/applications/alpha/RetiroIngreso'))
);
const AlphaOtros = Loader(
  lazy(() => import('src/content/applications/alpha/Otros'))
);
const Beta = Loader(lazy(() => import('src/content/dashboards/Beta')));
const Delta = Loader(lazy(() => import('src/content/dashboards/Delta')));
const Eagle = Loader(lazy(() => import('src/content/dashboards/Eagle')));
const Gamma = Loader(lazy(() => import('src/content/dashboards/Gamma')));
const Kappa = Loader(lazy(() => import('src/content/dashboards/Kappa')));
const Tao = Loader(lazy(() => import('src/content/dashboards/Tao')));
const Zeta = Loader(lazy(() => import('src/content/dashboards/Zeta')));

// Sigma Administration
const SigmaOperadores = Loader(
  lazy(() => import('src/content/administracion/operadores'))
);
const SigmaServidores = Loader(
  lazy(() => import('src/content/administracion/servidores'))
);
const SigmaCentinels = Loader(
  lazy(() => import('src/content/administracion/theoracleremote'))
);
const SigmaProductosyServicios = Loader(
  lazy(() => import('src/content/administracion/productos'))
);

const SigmaCobranzas = Loader(
  lazy(() => import('src/content/administracion/cobranzas'))
);

const SigmaCuentas = Loader(
  lazy(() => import('src/content/administracion/cuentas'))
);

const Alpha_DGCores = Loader(
  lazy(() => import('src/content/administracion/alpha_dgcores/DetalleDGCores'))
);
const Alpha_Dispositivos = Loader(
  lazy(
    () =>
      import(
        'src/content/administracion/alfa_dispositivos/DetalleAlfa_dispositivos'
      )
  )
);
//C:\xampp\htdocs\domosigma\front_sigma\src\content\administracion\cuentas\CuentasCard\ListadoCuentas\DetalleCuentas
const SigmaCuentasEdit = Loader(
  lazy(
    () =>
      import('src/content/administracion/cuentas/CuentasCard/DetalleCuentas')
  )
);
// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);
const SinServicio = Loader(
  lazy(() => import('src/content/pages/Status/SinServicio'))
);
const SauronEye = Loader(
  lazy(() => import('src/content/administracion/sauron'))
);
export const PrivateRoute = ({ children }) => {
  //https://stackoverflow.com/questions/67346060/not-able-to-access-session-storage-while-page-routing-in-reactjs
  ////ACAAA
  //https://www.google.com/search?q=window.sessionStorage.getItem+routes&sxsrf=APq-WBvlxCiF4AjC7h0KJdSUJjEnRhR4-w%3A1650937836987&ei=7E9nYqzjO8Pe1sQP_pCOgAE&ved=0ahUKEwisicfazrD3AhVDr5UCHX6IAxAQ4dUDCA4&uact=5&oq=window.sessionStorage.getItem+routes&gs_lcp=Cgdnd3Mtd2l6EAM6BwgjELADECc6CQgAELADEAcQHjoLCAAQsAMQBxAKEB46CAgAELADEMsBOgcIABCwAxAeOgUIABDLAToECAAQHjoGCAAQBRAeOgYIABAWEB46BQghEKABOgcIIRAKEKABSgQIQRgBSgQIRhgAUKorWKI4YKI8aAFwAHgAgAGXAogB1waSAQU0LjIuMZgBAKABAcgBCsABAQ&sclient=gws-wiz
  const data = window.sessionStorage.getItem('alphaSS');

  let isAuthenticated = false;
  //console.log(data);
  if (data) {
    try {
      isAuthenticated = true;
      var decoded = JSON.parse(data) as Sigma_Account_Auth;
      //console.log(decoded.cliente);

      var dateNow = new Date();

      if (decoded.exp * 1000 < dateNow.getTime()) {
        alert('Session Expirada, vuelva a loggearse');
        window.sessionStorage.removeItem('tokenSigma');
        // window.sessionStorage.removeItem('_clientMeta');
        // window.sessionStorage.removeItem('_clientAV');
        // window.sessionStorage.removeItem('_clientUA');
        window.sessionStorage.removeItem('alphaSS');
        window.location.replace('/');
        // }
      }
    } catch (error) {
      console.log(decoded);
      console.log(error);
      isAuthenticated = false;
    }
  }

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/login" replace />;
};

export const PrivateMatrixRoute = ({ children }) => {
  //https://stackoverflow.com/questions/67346060/not-able-to-access-session-storage-while-page-routing-in-reactjs
  ////ACAAA
  //https://www.google.com/search?q=window.sessionStorage.getItem+routes&sxsrf=APq-WBvlxCiF4AjC7h0KJdSUJjEnRhR4-w%3A1650937836987&ei=7E9nYqzjO8Pe1sQP_pCOgAE&ved=0ahUKEwisicfazrD3AhVDr5UCHX6IAxAQ4dUDCA4&uact=5&oq=window.sessionStorage.getItem+routes&gs_lcp=Cgdnd3Mtd2l6EAM6BwgjELADECc6CQgAELADEAcQHjoLCAAQsAMQBxAKEB46CAgAELADEMsBOgcIABCwAxAeOgUIABDLAToECAAQHjoGCAAQBRAeOgYIABAWEB46BQghEKABOgcIIRAKEKABSgQIQRgBSgQIRhgAUKorWKI4YKI8aAFwAHgAgAGXAogB1waSAQU0LjIuMZgBAKABAcgBCsABAQ&sclient=gws-wiz
  const data = window.sessionStorage.getItem('alphaAA');

  let isAuthenticated = false;
  //console.log(data);
  if (data) {
    try {
      isAuthenticated = true;
      var decoded = JSON.parse(data) as ISigma_Admin_Autorizada;
      //console.log(decoded.cliente);

      var dateNow = new Date();

      if (decoded.exp * 1000 < dateNow.getTime()) {
        alert('Session Expirada, vuelva a loggearse');
        window.sessionStorage.removeItem('tokenAdmin');
        // window.sessionStorage.removeItem('_clientMeta');
        // window.sessionStorage.removeItem('_clientAV');
        // window.sessionStorage.removeItem('_clientUA');
        window.sessionStorage.removeItem('alphaAA');
        window.location.replace('/');
        // }
      }
    } catch (error) {
      console.log(decoded);
      console.log(error);
      isAuthenticated = false;
    }
  }

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/thematrix" replace />;
};

export const PrivateCheckRol = ({ children }) => {
  const data = window.sessionStorage.getItem('alphaAA');
  const location = useLocation();
  let hasRol = false;
  //console.log(data);
  if (data) {
    try {
      var sigmaAccount = JSON.parse(data) as ISigma_Admin_Autorizada;
      //console.log(decoded.cliente);

      if (
        location.pathname.indexOf('/administracion/cuentas') > -1 &&
        sigmaAccount.adcu
      ) {
        hasRol = true;
      }
      if (
        location.pathname.indexOf('/administracion/operadores') > -1 &&
        sigmaAccount.adad
      ) {
        hasRol = true;
      }
      if (
        location.pathname.indexOf('/administracion/servidores') > -1 &&
        sigmaAccount.adse
      ) {
        hasRol = true;
      }
      if (
        location.pathname.indexOf('/administracion/cobranzas') > -1 &&
        sigmaAccount.adco
      ) {
        hasRol = true;
      }
      if (
        location.pathname.indexOf('/administracion/productos') > -1 &&
        sigmaAccount.apsb
      ) {
        hasRol = true;
      }
      if (
        location.pathname.indexOf('/administracion/inspector') > -1 &&
        (sigmaAccount.id == '666' ||
          sigmaAccount.id == '1' ||
          sigmaAccount.id == '7')
      ) {
        hasRol = true;
        alert('Not Ready Men');
      }
      if (
        location.pathname.indexOf('/administracion/darksauron') > -1 &&
        (sigmaAccount.id == '666' ||
          sigmaAccount.id == '1' ||
          sigmaAccount.id == '7')
      ) {
        hasRol = true;
        alert('Not Ready Men');
      }
      if (
        location.pathname.indexOf('/administracion/theoracle') > -1 &&
        parseInt(sigmaAccount.id) > 0 &&
        parseInt(sigmaAccount.id) < 10
      ) {
        hasRol = true;
      }
      if (location.pathname.indexOf('/administracion/sauron') > -1) {
        hasRol = true;
      }
    } catch (error) {
      console.log(sigmaAccount);
      console.log(error);
      hasRol = false;
    }
  }

  if (hasRol) {
    return children;
  } else {
    alert('A donde queremos ir toro????');
  }

  return <Navigate to="/thematrix" replace />;
};

export const CheckServicioContratado = ({ children, Servicio }) => {
  const data = window.sessionStorage.getItem('alphaSS');
  const location = useLocation();
  let hasService = false;

  if (data) {
    try {
      hasService = false;
      var sigmaAccount = JSON.parse(data) as Sigma_Account_Auth;
      if (
        location.pathname.indexOf('applications/alpha') > -1 &&
        sigmaAccount.cores &&
        sigmaAccount.cores.length > 0
      ) {
        hasService = true;
      }
      if (
        location.pathname.indexOf('dashboards/alpha') > -1 &&
        sigmaAccount.cores &&
        sigmaAccount.cores.length > 0
      ) {
        hasService = true;
      }
      if (
        location.pathname.indexOf('dashboards/beta') > -1 &&
        sigmaAccount.beta > 0
      ) {
        hasService = true;
      }
      if (
        location.pathname.indexOf('dashboards/delta') > -1 &&
        sigmaAccount.delta > 0
      ) {
        hasService = true;
      }
      if (
        location.pathname.indexOf('dashboards/kappa') > -1 &&
        sigmaAccount.kappa > 0
      ) {
        hasService = true;
      }
      if (
        location.pathname.indexOf('dashboards/gamma') > -1 &&
        sigmaAccount.gamma > 0
      ) {
        hasService = true;
      }
      if (
        location.pathname.indexOf('dashboards/tao') > -1 &&
        sigmaAccount.tao > 0
      ) {
        hasService = true;
      }
      if (
        location.pathname.indexOf('dashboards/zeta') > -1 &&
        sigmaAccount.zeta > 0
      ) {
        hasService = true;
      }
      //console.log(decoded.cliente);
    } catch (error) {
      console.log(sigmaAccount);
      console.log(error);
      hasService = false;
    }
  }

  if (hasService) {
    return children;
  }
  const urlNavigate = '/status/sinservicio/' + Servicio;
  return <Navigate to={urlNavigate} replace />;
};

const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboards/Eagle" replace />
      },
      {
        path: 'overview',
        element: (
          <PrivateMatrixRoute>
            <Overview />
          </PrivateMatrixRoute>
        )
      },
      {
        path: 'login',
        element: <LoginSigma />
      },
      {
        path: 'thematrix',
        element: <LoginAdmin />
      },
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          },
          {
            path: 'sinservicio',
            element: (
              <PrivateRoute>
                <SidebarLayout />
              </PrivateRoute>
            ),
            children: [
              {
                path: '*',
                element: <SinServicio />
              }
            ]
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },

  {
    path: 'applications',
    element: (
      <PrivateRoute>
        <SidebarLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/applications/alpha" replace />
      },
      {
        path: 'alpha',
        children: [
          {
            path: 'pedidos',
            children: [
              {
                path: '*',
                element: (
                  <PrivateRoute>
                    <AlphaPedidos />
                  </PrivateRoute>
                )
              }
            ]
          },
          {
            path: 'comprobantes',
            children: [
              {
                path: '*',
                element: (
                  <PrivateRoute>
                    <AlphaComprobantes />
                  </PrivateRoute>
                )
              }
            ]
          },
          {
            path: 'clientes',
            children: [
              {
                path: '*',
                element: (
                  <PrivateRoute>
                    <AlphaClientes />
                  </PrivateRoute>
                )
              }
            ]
          },
          {
            path: 'productos',
            children: [
              {
                path: '*',
                element: (
                  <PrivateRoute>
                    <AlphaProductos />
                  </PrivateRoute>
                )
              }
            ]
          },
          {
            path: 'inspectorcaja',
            children: [
              {
                path: '*',
                element: (
                  <PrivateRoute>
                    <AlphaCaja />
                  </PrivateRoute>
                )
              }
            ]
          },

          {
            path: 'retiroingreso',
            children: [
              {
                path: '*',
                element: (
                  <PrivateRoute>
                    <AlphaRetiroIngreso />
                  </PrivateRoute>
                )
              }
            ]
          },
          {
            path: 'Otros',
            children: [
              {
                path: '*',
                element: (
                  <PrivateRoute>
                    <AlphaOtros />
                  </PrivateRoute>
                )
              }
            ]
          },
          {
            path: '*',
            element: (
              <PrivateRoute>
                <AlphaCore />
              </PrivateRoute>
            )
          }
        ]
      }
    ]
  },

  {
    path: 'dashboards',
    element: (
      <PrivateRoute>
        <SidebarLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboards/Eagle" replace />
      },
      {
        path: 'Eagle',
        element: <Eagle />
      },
      {
        path: 'alpha',
        element: (
          <CheckServicioContratado Servicio="Alpha">
            <Alpha />
          </CheckServicioContratado>
        )
      },
      {
        path: 'beta',
        element: (
          <CheckServicioContratado Servicio="Beta">
            <Beta />
          </CheckServicioContratado>
        )
      },
      {
        path: 'delta',
        element: (
          <CheckServicioContratado Servicio="Delta">
            <Delta />
          </CheckServicioContratado>
        )
      },
      {
        path: 'gamma',
        element: (
          <CheckServicioContratado Servicio="Gamma">
            <Gamma />
          </CheckServicioContratado>
        )
      },
      {
        path: 'kappa',
        element: (
          <CheckServicioContratado Servicio="Kappa">
            <Kappa />
          </CheckServicioContratado>
        )
      },
      {
        path: 'tao',
        element: (
          <CheckServicioContratado Servicio="Tao">
            <Tao />
          </CheckServicioContratado>
        )
      },
      ,
      {
        path: 'zeta',
        element: (
          <CheckServicioContratado Servicio="Zeta">
            <Zeta />
          </CheckServicioContratado>
        )
      }
    ]
  },
  {
    path: 'administracion',
    element: (
      <PrivateMatrixRoute>
        <SidebarLayoutMatrix />
      </PrivateMatrixRoute>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/administracion/sauron" replace />
      },
      {
        path: 'default',
        element: <StatusComingSoon />
      },

      {
        path: 'cuentas',
        children: [
          {
            path: '/',
            element: (
              <PrivateCheckRol>
                <SigmaCuentas />
              </PrivateCheckRol>
            )
          },
          {
            path: ':id',
            element: (
              <PrivateCheckRol>
                <SigmaCuentasEdit />
              </PrivateCheckRol>
            )
          },
          {
            path: ':idCuenta/dgcores/:idCore',
            element: (
              <PrivateCheckRol>
                <Alpha_DGCores />
              </PrivateCheckRol>
            )
          },
          {
            path: ':idCuenta/dgcores/:idCore/dgdispo/:idDispo',
            element: (
              <PrivateCheckRol>
                <Alpha_Dispositivos />
              </PrivateCheckRol>
            )
          }
        ]
      },
      {
        path: 'operadores',
        element: (
          <PrivateCheckRol>
            <SigmaOperadores />
          </PrivateCheckRol>
        )
      },
      {
        path: 'servidores',
        element: (
          <PrivateCheckRol>
            <SigmaServidores />
          </PrivateCheckRol>
        )
      },
      {
        path: 'cobranzas',
        element: (
          <PrivateCheckRol>
            <SigmaCobranzas />
          </PrivateCheckRol>
        )
      },
      {
        path: 'productos',
        element: (
          <PrivateCheckRol>
            <SigmaProductosyServicios />
          </PrivateCheckRol>
        )
      },
      {
        path: 'inspector',
        element: (
          <PrivateCheckRol>
            <StatusComingSoon />
          </PrivateCheckRol>
        )
      },
      {
        path: 'theoracle',
        element: (
          <PrivateCheckRol>
            <SigmaCentinels />
          </PrivateCheckRol>
        )
      },
      {
        path: 'zetawhatchdog',
        element: (
          <PrivateCheckRol>
            {' '}
            <StatusComingSoon />
          </PrivateCheckRol>
        )
      },
      {
        path: 'sauron',
        element: (
          <PrivateCheckRol>
            <SauronEye />
          </PrivateCheckRol>
        )
      },
      {
        path: 'Default',
        element: <Default />
      },
      {
        path: 'crypto',
        element: <Crypto />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'management',
    element: (
      <PrivateMatrixRoute>
        <SidebarLayoutMatrix />
      </PrivateMatrixRoute>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/management/transactions" replace />
      },
      {
        path: 'transactions',
        element: (
          <PrivateMatrixRoute>
            <Transactions />
          </PrivateMatrixRoute>
        )
      },
      {
        path: 'profile',
        children: [
          {
            path: '/',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: (
              <PrivateMatrixRoute>
                <UserProfile />
              </PrivateMatrixRoute>
            )
          },
          {
            path: 'settings',
            element: (
              <PrivateMatrixRoute>
                <UserSettings />
              </PrivateMatrixRoute>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'components',
    element: (
      <PrivateMatrixRoute>
        <SidebarLayoutMatrix />
      </PrivateMatrixRoute>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/components/buttons" replace />
      },
      {
        path: 'buttons',
        element: (
          <PrivateMatrixRoute>
            <Buttons />
          </PrivateMatrixRoute>
        )
      },
      {
        path: 'modals',
        element: (
          <PrivateMatrixRoute>
            <Modals />
          </PrivateMatrixRoute>
        )
      },
      {
        path: 'accordions',
        element: (
          <PrivateMatrixRoute>
            <Accordions />
          </PrivateMatrixRoute>
        )
      },
      {
        path: 'tabs',
        element: (
          <PrivateMatrixRoute>
            <Tabs />
          </PrivateMatrixRoute>
        )
      },
      {
        path: 'badges',
        element: (
          <PrivateMatrixRoute>
            <Badges />
          </PrivateMatrixRoute>
        )
      },
      {
        path: 'tooltips',
        element: (
          <PrivateMatrixRoute>
            <Tooltips />
          </PrivateMatrixRoute>
        )
      },
      {
        path: 'avatars',
        element: (
          <PrivateMatrixRoute>
            <Avatars />
          </PrivateMatrixRoute>
        )
      },
      {
        path: 'cards',
        element: (
          <PrivateMatrixRoute>
            <Cards />
          </PrivateMatrixRoute>
        )
      },
      {
        path: 'forms',
        element: (
          <PrivateMatrixRoute>
            <Forms />
          </PrivateMatrixRoute>
        )
      }
    ]
  }
];

export default routes;
