import { Typography, Avatar, Grid } from '@mui/material';
import { red } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import {
  ISigma_Admin_Autorizada,
  Sigma_Account_Auth
} from 'src/models/sigma/Sigma_Account_Auth';

// dashTittle?: string, subHeading?: string
function PageHeader({ DashTittle, subHeading }) {
  let dataContacto = 'Non Defined';
  let dataCliente = 'Non Defined';
  let clientMessaje = 'Veamos como estan tus negocios hoy.';
  try {
    const data = window.sessionStorage.getItem('alphaSS');
    const decoded = JSON.parse(data) as Sigma_Account_Auth;
    dataContacto = decoded.contacto;
    dataCliente = decoded.cliente;
  } catch (error) {
    try {
      const data = window.sessionStorage.getItem('alphaAA');
      const decoded = JSON.parse(data) as ISigma_Admin_Autorizada;
      dataContacto = decoded.NOMBRE;
      dataCliente = 'Matrix Operator';
      clientMessaje = 'Let see where are the Sentinels, get ready the EMP!';
    } catch (error) {}
  }

  const user = {
    name: dataContacto,
    cliente: dataCliente,
    avatar: '/static/images/avatars/4.jpg'
  };
  const theme = useTheme();

  const responsiveAvatarStyle = {
    mr: 2,
    width: theme.spacing(6),
    height: theme.spacing(6),
    [theme.breakpoints.only('lg')]: {
      width: theme.spacing(8),
      height: theme.spacing(8)
    }
  };

  return (
    <Grid container alignItems="normal" spacing={4}>
      <Grid item xs={2} sm={1} md={1} lg={1}>
        <Avatar
          sx={responsiveAvatarStyle}
          variant="rounded"
          alt={user.name}
          src={user.avatar}
        />
      </Grid>
      <Grid item xs={10} sm={7} md={7} lg={7}>
        <Typography variant="h3" component="h3" gutterBottom>
          Bienvenido, {user.name}!
        </Typography>
        <Typography variant="subtitle2">{clientMessaje}</Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Typography textAlign={'end'} variant="h3" component="h3" gutterBottom>
          {DashTittle}
        </Typography>
        <Typography textAlign={'end'} variant="subtitle2">
          {subHeading}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
