import { ReactNode } from 'react';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import StoreIcon from '@mui/icons-material/Store'; //alpha
import ReceiptIcon from '@mui/icons-material/Receipt'; //beta
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone'; //delta
import PreviewIcon from '@mui/icons-material/Preview'; //kappa
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone'; //tao
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone'; //gama
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Color } from '@mui/material';
export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
  modulo?: string;
  color?: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Eagle Eye',
        link: '/',
        icon: RemoveRedEyeOutlinedIcon
      }
    ]
  },
  {
    heading: 'DOMO APPS',
    items: [
      {
        name: 'Alpha - Locales',
        icon: StoreIcon,
        link: '/dashboards/alpha',

        items: [
          {
            name: 'Alpha Dashboard',
            link: '/dashboards/alpha'
          }
        ]
      },
      {
        name: 'Beta Cianbox - FE',
        icon: ReceiptIcon,
        link: '/dashboards/beta',
        modulo: 'beta'
      },
      {
        name: 'Delta Ecommerce',
        icon: ShoppingCartTwoToneIcon,
        link: '/dashboards/delta',
        modulo: 'delta'
      },
      {
        name: 'Kappa CCTV',
        icon: PreviewIcon,
        link: '/dashboards/kappa',
        modulo: 'kappa'
      },
      {
        name: 'Gamma - Warehouse',
        icon: Inventory2TwoToneIcon,
        link: '/dashboards/gamma',
        modulo: 'gamma'
      },
      {
        name: 'Tao - Prod & Diseño',
        icon: DesignServicesTwoToneIcon,
        link: '/dashboards/tao',
        modulo: 'tao'
      },
      {
        name: 'Zeta - WhatsApp ',
        icon: WhatsAppIcon,
        link: '/dashboards/zeta',
        modulo: 'zeta'
      }
    ]
  }
];

export default menuItems;
