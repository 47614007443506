import { ListSubheader, List } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import SidebarMenuItem from './item';
import menuItems, { MenuItem } from './items';
import { styled } from '@mui/material/styles';
import {
  ISigma_Admin_Autorizada,
  Sigma_Account_Auth
} from 'src/models/sigma/Sigma_Account_Auth';

import StoreIcon from '@mui/icons-material/Store'; //alpha
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StorageIcon from '@mui/icons-material/Storage';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FindReplaceIcon from '@mui/icons-material/FindReplace';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
const data = window.sessionStorage.getItem('alphaAA');
var sigmaAccount = JSON.parse(data) as ISigma_Admin_Autorizada;

const MenuWrapper = styled(List)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(1)};
    padding: 0;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(2)} ${theme.spacing(2)};
    }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.sidebar.menuItemHeadingColor};
      padding: ${theme.spacing(0.8, 2)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(List)(
  ({ theme }) => `
    &.MuiList-root {
      padding: 0;

      .MuiList-root .MuiList-root .MuiListItem-root .MuiButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 2px ${theme.spacing(2)};
    
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(13)};
          padding-top: ${theme.spacing(0.8)};
          padding-bottom: ${theme.spacing(0.8)};
          position: relative;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(4)};

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(9)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(26)};
            margin-right: ${theme.spacing(1.5)};
            color: ${theme.sidebar.menuItemIconColor};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(22)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }

        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px ${theme.spacing(0)};

            .MuiButton-root {
              font-size: ${theme.typography.pxToRem(13)};
              padding: ${theme.spacing(0.5, 2, 0.5, 6.5)};

              &.Mui-active,
              &:hover {
                background-color: ${theme.sidebar.menuItemBg};
              }
         
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({
  items,
  path
}: {
  items: MenuItem[];
  path: string;
}): JSX.Element => (
  <SubMenuWrapper>
    {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
  </SubMenuWrapper>
);

const reduceChildRoutes = ({
  ev,
  path,
  item
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
}): Array<JSX.Element> => {
  const key = item.name;

  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false
          },
          path
        )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
        colorMUI_type={item.colorMUI_type}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
        colorMUI_type={item.colorMUI_type}
      />
    );
  }

  return ev;
};

function SidebarMenu() {
  const location = useLocation();

  try {
    menuItems[0].items = [];
    var nuevoItemSauron: MenuItem = {
      link: `/administracion/sauron`,
      icon: RemoveRedEyeOutlinedIcon,
      name: 'Sauron Eye',
      colorMUI_type: 'warning'
    };
    menuItems[0].items.push(nuevoItemSauron);
    var nuevoItem0: MenuItem = {
      link: `/administracion/zetawhatchdog`,
      icon: WhatsAppIcon,
      name: 'Zeta Watch Dog'
    };
    menuItems[0].items.push(nuevoItem0);

    if (sigmaAccount) {
      if (
        sigmaAccount.id == '666' ||
        sigmaAccount.id == '1' ||
        sigmaAccount.id == '7'
      ) {
        var nuevoItem: MenuItem = {
          link: `/administracion/inspector`,
          icon: ArchitectureIcon,
          name: 'The Architect'
        };
        var nuevoItemSauron: MenuItem = {
          link: `/administracion/darksauron`,
          icon: RemoveRedEyeOutlinedIcon,
          name: 'Dark Sauron Eye'
        };
        menuItems[0].items.push(nuevoItemSauron);
        menuItems[0].items.push(nuevoItem);
      }
      if (parseInt(sigmaAccount.id) > 0 && parseInt(sigmaAccount.id) < 10) {
        var nuevoItem: MenuItem = {
          link: `/administracion/theoracle`,
          icon: FindReplaceIcon,
          name: 'The Oracle Remote'
        };
        menuItems[0].items.push(nuevoItem);
      }

      menuItems[1].items = [];

      if (sigmaAccount.adcu) {
        var nuevoItem: MenuItem = {
          link: `/administracion/cuentas`,
          icon: StoreIcon,
          name: 'Cuentas Sigma'
        };
        menuItems[1].items.push(nuevoItem);
      }
      if (sigmaAccount.adad) {
        var nuevoItem: MenuItem = {
          link: `/administracion/operadores`,
          icon: LocalShippingIcon,
          name: 'Operador Nebuchadnezzar'
        };
        menuItems[1].items.push(nuevoItem);
      }
      if (sigmaAccount.adse) {
        var nuevoItem: MenuItem = {
          link: `/administracion/servidores`,
          icon: StorageIcon,
          name: 'Servidores'
        };
        menuItems[1].items.push(nuevoItem);
      }
      if (sigmaAccount.adco) {
        var nuevoItem: MenuItem = {
          link: `/administracion/cobranzas`,
          icon: LocalAtmIcon,
          name: 'Cobranzas'
        };
        menuItems[1].items.push(nuevoItem);
      }
      if (sigmaAccount.apsb) {
        var nuevoItem: MenuItem = {
          link: `/administracion/productos`,
          icon: SportsEsportsIcon,
          name: 'Productos y Servicios'
        };
        menuItems[1].items.push(nuevoItem);
      }
    } else {
      console.log('No tiene permisos algunos');
    }
  } catch (error) {
    console.log(error);
  }

  // console.log(menuItems[1]);

  return (
    <>
      {menuItems.map((section) => (
        <MenuWrapper
          key={section.heading}
          subheader={
            <ListSubheader component="div" disableSticky>
              {section.heading}
            </ListSubheader>
          }
        >
          {renderSidebarMenuItems({
            items: section.items,
            path: location.pathname
          })}
        </MenuWrapper>
      ))}
    </>
  );
}

export default SidebarMenu;
