import axios from 'axios';
//creating function to load ip address from the API
const GetIPData = async () => {
  const res = await axios.get('https://geolocation-db.com/json/');
  //console.log(res.data);
  window.sessionStorage.setItem('_clientMeta', JSON.stringify(res.data));
  window.sessionStorage.setItem(
    '_clientUA',
    JSON.stringify(navigator.userAgent)
  );
  window.sessionStorage.setItem(
    '_clientAV',
    JSON.stringify(navigator.platform)
  );
  return res.data;
};
export function GenerateRandomPassword() {
  var chars =
    '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var passwordLength = 12;
  var password = '';
  for (var i = 0; i <= passwordLength; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }
  return password;
}
export default GetIPData();
export function getMargenHoras(startDate: Date) {
  let inicio: Date = startDate;
  inicio.setHours(0, 0, 0);
  let fin: Date = new Date(startDate);
  fin.setHours(23, 59, 59);
  return { inicio, fin };
}
export function getMargenHorasDosFechas(startDate: Date, endDate: Date) {
  let inicio: Date = startDate;
  inicio.setHours(0, 0, 0);
  let fin: Date = new Date(endDate);
  fin.setHours(23, 59, 59);
  return { inicio, fin };
}
export function CheteateLaFecha(fechafile: string) {
  let fechaResult = fechafile;
  try {
    let splitDate = fechafile.split('T', 2);

    let fecha = ` ${splitDate[0].substring(8, 10)}/${splitDate[0].substring(
      5,
      7
    )}/${splitDate[0].substring(0, 4)}`;

    let tiempo = ` ${splitDate[1].substring(0, 2)}:${splitDate[1].substring(
      3,
      5
    )}:${splitDate[1].substring(6, 8)}`;

    fechaResult = fecha + ' ' + tiempo;
  } catch (error) {
    if (fechaResult.length >= 19) fechaResult = fechaResult.substring(0, 19);
  }

  return fechaResult;
}
export function CheteateLaFecha2(fechafile: Date) {
  let fechaResult = fechafile;

  try {
    if (fechafile) {
      return CheteateLaFecha(fechafile.toString());
    }
  } catch (error) {}

  return fechaResult;
}
