import { ReactNode } from 'react';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import BeachAccessTwoToneIcon from '@mui/icons-material/BeachAccessTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import FilterVintageTwoToneIcon from '@mui/icons-material/FilterVintageTwoTone';
import HowToVoteTwoToneIcon from '@mui/icons-material/HowToVoteTwoTone';
import LocalPharmacyTwoToneIcon from '@mui/icons-material/LocalPharmacyTwoTone';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import TrafficTwoToneIcon from '@mui/icons-material/TrafficTwoTone';
import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';
import LaptopChromebookOutlinedIcon from '@mui/icons-material/LaptopChromebookOutlined';
import SupportIcon from '@mui/icons-material/Support';
import StoreIcon from '@mui/icons-material/Store'; //alpha
import ReceiptIcon from '@mui/icons-material/Receipt'; //beta
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PreviewIcon from '@mui/icons-material/Preview'; //kappa
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone'; //tao
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone'; //gama

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
  modulo?: string;
  colorMUI_type?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      // {
      //   name: 'Sauron Eye',
      //   link: '/administracion/sauron',
      //   icon: RemoveRedEyeOutlinedIcon
      // },
      // {
      //   name: 'Zeta Watch Dog',
      //   link: '/administracion/zetawhatchdog',
      //   icon: WhatsAppIcon
      // }
    ]
  },
  {
    heading: 'The Matrix',
    items: [
      {
        name: 'Nested Option',
        icon: StoreIcon,
        link: '/dashboards/alpha',
        items: [
          {
            name: 'Alpha Dashboard',
            link: '/administracion'
          }
        ]
      },
      {
        name: 'Unique Option',
        icon: ReceiptIcon,
        link: '/administracion/3',
        modulo: 'beta'
      },
      {
        name: 'Unique Option',
        icon: ReceiptIcon,
        link: '/administracion/2',
        modulo: 'beta'
      }
    ]
  },
  {
    heading: 'HERRAMIENTAS',
    items: [
      {
        name: 'Soporte',
        link: '/administracion/soporte',
        icon: SupportIcon
      },
      {
        name: 'Whatsapp Directo',
        icon: MmsTwoToneIcon,
        link: '/administracion/messenger'
      },
      {
        name: 'Hacelo vos mismo',
        icon: MmsTwoToneIcon,
        link: '/administracion/messenger'
      }
    ]
  },
  {
    heading: 'Dashboards',
    items: [
      {
        name: 'Default',
        link: '/administracion/default',
        icon: LaptopChromebookOutlinedIcon
      },
      {
        name: 'Crypto',
        link: '/administracion/crypto',
        icon: BrightnessLowTwoToneIcon
      },
      {
        name: 'Messenger',
        icon: MmsTwoToneIcon,
        link: '/administracion/messenger'
      }
    ]
  },
  {
    heading: 'Management',
    items: [
      {
        name: 'Transactions',
        icon: TableChartTwoToneIcon,
        link: '/management/transactions'
      },
      {
        name: 'User Profile',
        icon: AccountCircleTwoToneIcon,
        link: '/management/profile',
        items: [
          {
            name: 'Profile Details',
            link: '/management/profile/details'
          },
          {
            name: 'User Settings',
            link: '/management/profile/settings'
          }
        ]
      }
    ]
  },
  {
    heading: 'Components',
    items: [
      {
        name: 'Buttons',
        icon: BallotTwoToneIcon,
        link: '/components/buttons'
      },
      {
        name: 'Modals',
        icon: BeachAccessTwoToneIcon,
        link: '/components/modals'
      },
      {
        name: 'Accordions',
        icon: EmojiEventsTwoToneIcon,
        link: '/components/accordions'
      },
      {
        name: 'Tabs',
        icon: FilterVintageTwoToneIcon,
        link: '/components/tabs'
      },
      {
        name: 'Badges',
        icon: HowToVoteTwoToneIcon,
        link: '/components/badges'
      },
      {
        name: 'Tooltips',
        icon: LocalPharmacyTwoToneIcon,
        link: '/components/tooltips'
      },
      {
        name: 'Avatars',
        icon: RedeemTwoToneIcon,
        link: '/components/avatars'
      },
      {
        name: 'Cards',
        icon: SettingsTwoToneIcon,
        link: '/components/cards'
      },
      {
        name: 'Forms',
        icon: TrafficTwoToneIcon,
        link: '/components/forms'
      }
    ]
  },
  {
    heading: 'Extra Pages',
    items: [
      {
        name: 'Status',
        icon: VerifiedUserTwoToneIcon,
        link: '/status',
        items: [
          {
            name: 'Error 404',
            link: '/status/404'
          },
          {
            name: 'Error 500',
            link: '/status/500'
          },
          {
            name: 'Maintenance',
            link: '/status/maintenance'
          },
          {
            name: 'Coming Soon',
            link: '/status/coming-soon'
          }
        ]
      }
    ]
  }
];

export default menuItems;
