import {
  Box,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import SupportIcon from '@mui/icons-material/Support';
import StoreIcon from '@mui/icons-material/Store'; //alpha
import ReceiptIcon from '@mui/icons-material/Receipt'; //beta

import CheckroomIcon from '@mui/icons-material/Checkroom'; //productois
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; //retiros e ingresos
import ContactPageIcon from '@mui/icons-material/ContactPage'; //Clientes
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'; //pedidos
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone'; //otros

import { Sigma_Account_Auth } from 'src/models/sigma/Sigma_Account_Auth';
const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

enum SubModuleApps {
  APP_ALPHA = 1,
  DASH_ALPHA,
  DASH_BETA,
  DASH_DELTA,
  DASH_KAPPA,
  DASH_GAMMA,
  DASH_TAO,
  DASH_ZETA,
  NOTDEFINED
}
function EagleEyeMenu() {
  var domoSubmodulo = '';

  const data = window.sessionStorage.getItem('alphaSS');
  var sigmaAccount = JSON.parse(data) as Sigma_Account_Auth;

  const location = useLocation();
  function dameIdLocalName() {
    let localName = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    );
    let localSTR = location.pathname.substring(
      0,
      location.pathname.lastIndexOf('/')
    );
    localSTR = localSTR.substring(localSTR.lastIndexOf('/') + 1);
    let idLocal = parseInt(localSTR);
    // console.log(localSTR);
    localName = decodeURI(localName);
    return { idLocal, localName };
  }

  let { idLocal, localName } = dameIdLocalName();
  function getNewPath(params: string) {
    return params + `/${idLocal}/${encodeURI(localName)}`;
  }
  function getSubModuleName() {
    try {
      const existe = sigmaAccount.cores.find((item) => {
        if (item[0] == idLocal) {
          return item;
        }
      });
      if (existe) {
        // console.log(existe);
        if (!existe[3]) {
          return;
        }
      } else {
        return;
      }
      if (location.pathname.indexOf('applications/alpha') > -1) {
        domoSubmodulo = 'Módulos';

        return SubModuleApps.APP_ALPHA;
      }
      if (location.pathname.indexOf('dashboards/alpha') > -1) {
        return SubModuleApps.DASH_ALPHA;
      }
      if (location.pathname.indexOf('dashboards/beta') > -1) {
        return SubModuleApps.DASH_BETA;
      }
      if (location.pathname.indexOf('dashboards/delta') > -1) {
        return SubModuleApps.DASH_DELTA;
      }
      if (location.pathname.indexOf('dashboards/kappa') > -1) {
        return SubModuleApps.DASH_KAPPA;
      }
      if (location.pathname.indexOf('dashboards/gamma') > -1) {
        return SubModuleApps.DASH_GAMMA;
      }
      if (location.pathname.indexOf('dashboards/tao') > -1) {
        return SubModuleApps.DASH_TAO;
      }
      if (location.pathname.indexOf('dashboards/zeta') > -1) {
        return SubModuleApps.DASH_ZETA;
      }
      return SubModuleApps.NOTDEFINED;
    } catch (error) {
      return SubModuleApps.NOTDEFINED;
    }
  }

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  // var [appModuloName, setAppModuloName] = useState(SubModuleApps.NOTDEFINED);
  // setAppModuloName(getSubModuleName());

  var appModuloName = getSubModuleName();

  //console.log(appModuloName);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <ListWrapper>
        <List disablePadding component={Box} display="flex">
          <Hidden mdDown>
            <ListItem
              classes={{ root: 'MuiListItem-indicators' }}
              button
              component={NavLink}
              to="/dashboards/Eagle"
            >
              <RemoveRedEyeOutlinedIcon />
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary="Eagle Eye"
              />{' '}
            </ListItem>{' '}
          </Hidden>
          {appModuloName === SubModuleApps.APP_ALPHA ? (
            <ListItem
              classes={{ root: 'MuiListItem-indicators' }}
              button
              onClick={handleOpen}
              ref={ref}
            >
              <ViewModuleIcon />
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary={
                  <Box display="flex" alignItems="center">
                    {domoSubmodulo}
                    <Box display="flex" alignItems="center" pl={0.3}>
                      <ExpandMoreTwoToneIcon fontSize="small" />
                    </Box>
                  </Box>
                }
              />
            </ListItem>
          ) : (
            <></>
          )}{' '}
          <Hidden mdDown>
            <ListItem
              classes={{ root: 'MuiListItem-indicators' }}
              button
              component={NavLink}
              to="/components/forms"
            >
              <SupportIcon />
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary="Soporte"
              />
            </ListItem>
          </Hidden>
        </List>
      </ListWrapper>
      <Menu anchorEl={ref.current} onClose={handleClose} open={isOpen}>
        {/*
        
        <Menu anchorEl={ref.current} onClose={handleClose} open={isOpen}>


        /// */}
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to={getNewPath('/applications/alpha/')}
          onClick={handleClose}
        >
          <StoreIcon /> &nbsp;&nbsp;Alpha Dashboard - {localName}
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to={getNewPath('/applications/alpha/comprobantes')}
          onClick={handleClose}
        >
          <ReceiptIcon />
          &nbsp;&nbsp; Comprobantes
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to={getNewPath('/applications/alpha/pedidos')}
          onClick={handleClose}
        >
          <AddShoppingCartIcon />
          &nbsp;&nbsp; Pedidos
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to={getNewPath('/applications/alpha/retiroingreso')}
          onClick={handleClose}
        >
          <AttachMoneyIcon />
          &nbsp;&nbsp; Retiros E Ingresos
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to={getNewPath('/applications/alpha/inspectorcaja')}
          onClick={handleClose}
        >
          <AttachMoneyIcon />
          &nbsp;&nbsp; Inspector de Caja
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to={getNewPath('/applications/alpha/productos')}
          onClick={handleClose}
        >
          <CheckroomIcon />
          &nbsp;&nbsp; Productos
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to={getNewPath('/applications/alpha/clientes')}
          onClick={handleClose}
        >
          <ContactPageIcon /> &nbsp;&nbsp;Clientes
        </MenuItem>

        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to={getNewPath('/applications/alpha/otros')}
          onClick={handleClose}
        >
          <Inventory2TwoToneIcon /> &nbsp;&nbsp;Otros
        </MenuItem>
      </Menu>
    </>
  );
}

export default EagleEyeMenu;
