import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './base';
import { StylesProvider } from '@mui/styles';
import { useLocation } from 'react-router-dom';

export const ThemeContext = React.createContext(
  (themeName: string): void => {}
);

const ThemeProviderWrapper: React.FC = (props) => {
  let curThemeName =
    window.localStorage.getItem('appTheme') || 'GreyGooseTheme';
  const location = useLocation();
  if (
    location.pathname.indexOf('/thematrix') > -1 ||
    location.pathname.indexOf('/administracion') > -1
  ) {
    curThemeName =
      window.localStorage.getItem('appThemeMatrix') || 'GreyGooseTheme';
    curThemeName = 'NebulaFighterTheme' as any;
  } else {
    curThemeName = 'GreyGooseTheme' as any;
  }

  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName: string): void => {
    if (
      location.pathname.indexOf('/thematrix') > -1 ||
      location.pathname.indexOf('/administracion') > -1
    ) {
      window.localStorage.setItem('appThemeMatrix', themeName);
      _setThemeName(themeName);
    } else {
      window.localStorage.setItem('appTheme', themeName);
      _setThemeName(themeName);
    }
  };

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
