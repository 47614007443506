import { useRoutes } from 'react-router-dom';
import routes from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { ReactQueryDevtools } from 'react-query/devtools';
import GetIPData from './servicios/libs/helper.externos';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider
} from 'react-query';
import { useEffect } from 'react';

const queryClient = new QueryClient();

const App = () => {
  //process.env.TZ = 'America/Buenos_Aires';
  const content = useRoutes(routes);
  //creating IP state

  //passing getData method to the lifecycle method
  GetIPData.then((val) => val).catch((err) => console.log(err));
  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener('appinstalled', () => {
        localStorage['SIGMAinstalledFrom'] = window.location.pathname;
      });
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          {content}
          <ReactQueryDevtools initialIsOpen={false} />
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
export default App;

///https://github.com/bloomui/tokyo-free-white-react-admin-dashboard/issues/4
